<template>
  <v-container>
    <!-- Header -->
    <header class="custom-header mb-6">
      <h4>පැකේජ භාවිතය</h4>
    </header>

    <v-data-table
      :footer-props="$store.state.dataTableFooterProps"
      :headers="headers"
      :items="records"
      :loading="loading"
      :options.sync="options"
      :server-items-length="total"
      class="custom-shadow custom-radius"
    >
      <template slot="no-data">ලබා ගත හැකි දත්ත නැත</template>
      <template v-slot:item.packageId="{ item }">{{item.package.name}}</template>
      <template v-slot:item.manualPackageId="{ item }">
        <span v-if="item.manualPackageId">{{item.manual_package.price}}</span>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { ApiService } from '../../services/api.service';
import { CHANGE_LOADING_STATE } from "../../store/mutation-types";

export default {
  data: () => ({
    headers: [
      { text: 'සාමාජික අංකය', value: 'customerId' },
      { text: 'මාර්ගගත පැක‌ේජය', value: `packageId` },
      { text: 'මාර්ගගත නොවන පැක‌ේජය', value: 'manualPackageId' },
      { text: 'ලබාගත් දිනය', value: 'created_at', align: 'end' },
    ],
    records: [],
    options: {},
    total: 0,
    loading: false
  }),

  mounted: async function () {
    this.$store.commit(CHANGE_LOADING_STATE, { active: true });
    await this.fetchPackageUsageLog();
    this.$store.commit(CHANGE_LOADING_STATE, { active: false });
  },

  watch: {
    options: {
      handler: function () {
        this.fetchPackageUsageLog();
      },
      deep: true,
    }
  },

  methods: {
    // Fetch records
    fetchPackageUsageLog: async function () {
      this.loading = true;

      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const order = sortDesc[0] ? 'desc' : 'asc';
      const pageOptions = itemsPerPage != -1 ? { perPage: itemsPerPage, page: page } : {};
      const sortOptions = sortBy.length != 0 ? { sortBy: sortBy[0], order: order } : {};

      try {
        const url = "package-usage";
        const params = { params: { ...pageOptions, ...sortOptions } };
        const response = await ApiService.get(url, params);
        this.records = response.data.data;
        this.total = response.data.meta.total;
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    }
  }
}
</script>